import {
  FieldMessage,
  FormControl,
  Input,
  InputProps,
} from '@mr-yum/frontend-ui'
import React, { PropsWithChildren } from 'react'
import { useController } from 'react-hook-form'

import { HookFormControlProps } from './shared'

export type InputFieldProps = InputProps & HookFormControlProps

export const InputField = ({
  name,
  label,
  disabled,
  caption,
  ...props
}: PropsWithChildren<InputFieldProps>) => {
  const { field, fieldState } = useController({ name })

  return (
    <FormControl
      label={label}
      disabled={disabled}
      caption={caption}
      feedback={
        !!fieldState.error && (
          <FieldMessage type="critical">
            {fieldState.error.message}
          </FieldMessage>
        )
      }
      htmlFor={name}
    >
      <Input
        {...props}
        {...field}
        disabled={disabled}
        error={!!fieldState.error}
        value={field.value === null ? '' : field.value}
      />
    </FormControl>
  )
}
